import { Variants } from "framer-motion";
import {
    AboutSection,
    PortfolioProject,
    Skill,
    Experience,
} from "./models/interfaces";
import { ProjectFilter } from "./models/projectFilter";
import { SectionIds } from "./models/sectionIds";
import { Technology } from "./models/technology";

const {
    Angular,
    AWS,
    Bash,
    Cucumber,
    CSharp,
    Express,
    Figma,
    Git,
    GraphQL,
    Handlebars,
    Heroku,
    H2,
    Java,
    JavaScript,
    Jest,
    JUnit,
    MongoDB,
    Node,
    PostgreSQL,
    Python,
    Quarkus,
    React,
    Redux,
    Remix,
    RxJS,
    SocketIO,
    SpringBoot,
    Tableau,
    TypeScript,
    Vue,
} = Technology;

const {
    Home,
    About,
    Experience: ExperienceSection,
    Skills,
    Projects,
    Contact,
} = SectionIds;

const { FullStackApplication, BackendApplication, Game, All } = ProjectFilter;

export const aboutSections: AboutSection[] = [
    {
        title: "Fundamentals",
        description:
            "I write efficient code with appropriate data structures and algorithms, ensure modularity with Object Oriented Programming, and prioritize testing and clean code for maintainability.",
        imageUrl: "/fundamentals.jpg",
    },
    {
        title: "Backend Development",
        description:
            "By focusing on databases (both relational and non-relational), logic, API design, architecture and distributed systems, I ensure applications perform correctly.",
        imageUrl: "/back-end.jpg",
    },
    {
        title: "Frontend Development",
        description:
            "I use JavaScript / TypeScript and their frameworks to build interactive interfaces. I strive to provide users with the best experience possible centered around sound design principles.",
        imageUrl: "/front-end.jpg",
    },
    {
        title: "Agile",
        description:
            "I thrive in Agile environments, using Scrum and Kanban, while leveraging strong communication and presentation skills for collaboration and stakeholder engagement.",
        imageUrl: "/agile.jpg",
    },
];

export const applicationSections: string[] = [
    Home,
    About,
    ExperienceSection,
    Skills,
    Projects,
    Contact,
];

export const slideFromLeft: Variants = {
    whileInView: {
        x: 0,
        opacity: 1,
        transition: {
            duration: 0.75,
            ease: "easeInOut",
        },
    },
    whileOutView: {
        x: "-100%",
        opacity: 0,
    },
};

export const slideFromRight: Variants = {
    whileInView: {
        x: 0,
        opacity: 1,
        transition: {
            duration: 0.6,
            ease: "easeInOut",
        },
    },
    whileOutView: {
        x: "50%",
        opacity: 0,
    },
};

export const skills: Skill[] = [
    {
        name: Java,
        imageUrl:
            "https://cdn.jsdelivr.net/gh/devicons/devicon@latest/icons/java/java-original.svg",
    },
    {
        name: SpringBoot,
        imageUrl:
            "https://cdn.jsdelivr.net/gh/devicons/devicon@latest/icons/spring/spring-original.svg",
    },
    {
        name: Quarkus,
        imageUrl:
            "https://cdn.jsdelivr.net/gh/devicons/devicon@latest/icons/quarkus/quarkus-original.svg",
    },
    {
        name: Node,
        imageUrl:
            "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/nodejs/nodejs-original.svg",
    },
    {
        name: PostgreSQL,
        imageUrl:
            "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/postgresql/postgresql-original.svg",
    },
    {
        name: MongoDB,
        imageUrl:
            "https://cdn.jsdelivr.net/gh/devicons/devicon@latest/icons/mongodb/mongodb-original.svg",
    },
    {
        name: GraphQL,
        imageUrl:
            "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/graphql/graphql-plain.svg",
    },
    {
        name: TypeScript,
        imageUrl:
            "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/typescript/typescript-original.svg",
    },
    {
        name: React,
        imageUrl:
            "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/react/react-original.svg",
    },
    {
        name: Remix,
        imageUrl: "https://avatars.githubusercontent.com/u/64235328?s=200&v=4",
    },
    {
        name: Angular,
        imageUrl:
            "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/angularjs/angularjs-original.svg",
    },
    {
        name: RxJS,
        imageUrl: "https://rxjs.dev/assets/images/favicons/favicon-192x192.png",
    },
    {
        name: Vue,
        imageUrl:
            "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/vuejs/vuejs-original.svg",
    },
    {
        name: Jest,
        imageUrl:
            "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/jest/jest-plain.svg",
    },
    {
        name: JUnit,
        imageUrl:
            "https://cdn.jsdelivr.net/gh/devicons/devicon@latest/icons/junit/junit-original.svg",
    },
    {
        name: Cucumber,
        imageUrl:
            "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/cucumber/cucumber-plain.svg",
    },
    {
        name: AWS,
        imageUrl:
            "https://cdn.jsdelivr.net/gh/devicons/devicon@latest/icons/amazonwebservices/amazonwebservices-original-wordmark.svg",
    },
    {
        name: Python,
        imageUrl:
            "https://cdn.jsdelivr.net/gh/devicons/devicon@latest/icons/python/python-original.svg",
    },
    {
        name: Bash,
        imageUrl:
            "https://cdn.jsdelivr.net/gh/devicons/devicon@latest/icons/bash/bash-original.svg",
    },
    {
        name: Git,
        imageUrl:
            "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/git/git-original.svg",
    },
];

export const workExperience: Experience[] = [
    {
        name: "Software Engineer",
        company: "MaibornWolff GmbH",
        achievements: [
            "Full-stack engineer (backend focus) for a vehicle damage inspection and assessment application. Developed business features using microservices with Java, Quarkus, MongoDB, TypeScript and React. Implemented automated testing with Jest and JUnit, and DevOps scripting with Python.",
            "Worked in Agile environment, contributing to user story refinements and delivering presentations to stakeholders on a regular basis.",
        ],
        period: "November 2024 - present",
        url: "https://www.maibornwolff.de/",
        techStack: [
            TypeScript,
            Python,
            Java,
            Quarkus,
            React,
            MongoDB,
            Jest,
            JUnit,
            Git,
            Bash,
        ],
    },
    {
        name: "Junior Software Engineer",
        company: "MaibornWolff GmbH",
        achievements: [
            "Full-stack engineer for an application that extends assistive robot aid for individuals with disabilities with an interface used for monitoring and management. Built application components with TypeScript, React, Remix, Prisma and PostgreSQL. Implemented automated and behaviour driven testing using Jest and Cucumber. Engineered custom design system with Storybook.",
            "Full-stack engineer for an electronic components configurator application. Implemented business features using TypeScript, Angular, C#, .NET and automated testing with Jest and xUnit.",
            "Frontend engineer for a shared e-mobility application comissioned by a leading German car manufacturer. Built and optimized components with Typescript and React, and managed deployments with GitLab.",
            "Frontend engineer for an application used to manage and gather data insights from different projects in the IT sector. Designed and built features using TypeScript, Vue.js, Vuetify and GraphQL.",
            "Collaborated in an Agile environment, communicating regularly with cross-functional teams and clients.",
        ],
        period: "November 2022 - November 2024",
        url: "https://www.maibornwolff.de/",
        techStack: [
            TypeScript,
            CSharp,
            Java,
            Quarkus,
            Angular,
            RxJS,
            Vue,
            React,
            Remix,
            PostgreSQL,
            MongoDB,
            GraphQL,
            Jest,
            JUnit,
            Git,
            Bash,
            Cucumber,
        ],
    },
    {
        name: "Full Stack Developer",
        company: "Spiced Academy",
        achievements: [
            "Built four full-stack applications, each with unique solutions and user experiences.",
            "Developed proficiency in integrating frontend and backend technologies using a variety of frameworks such as React, Vue and Node, with a deep understanding of core concepts in JavaScript and TypeScript. Worked with databases such as PostgreSQL and Redis to store and manage data.",
            "Successfully completed a rigorous full-time program, gaining comprehensive knowledge and skills in full-stack development.",
        ],
        period: "May 2022 - September 2022",
        url: "https://www.spiced-academy.com/en",
        techStack: [
            JavaScript,
            TypeScript,
            Vue,
            React,
            Redux,
            SocketIO,
            Jest,
            Node,
            Express,
            PostgreSQL,
            AWS,
            Git,
        ],
    },
    {
        name: "Digital Jobs Compass",
        company: "StartSteps",
        achievements: [
            "Introduction to data analytics, web development, UX/UI design, cybersecurity, digital marketing, project management and agile methodologies. Participated in hands-on projects across these domains.",
            "Foundational technical skills in Python and JavaScript, along with collaboration and problem-solving abilities.",
        ],
        period: "February 2022 - March 2022",
        url: "https://startsteps.org/en",
        techStack: [Python, JavaScript, Tableau, Figma, Git],
    },
];

export const projectFilters: string[] = [
    FullStackApplication,
    BackendApplication,
    Java,
    React,
    Vue,
    Game,
    All,
];

export const portfolioProjects: PortfolioProject[] = [
    {
        title: "Car Sharing",
        description:
            "A Java backend application that allows users to manage car rentals.",
        repositoryUrl: "https://github.com/JoaoTeixeira13/car-sharing",
        imageUrl: "/car-sharing.jpg",
        techStack: [Java, H2],
        tags: [BackendApplication, Java, All],
    },
    {
        title: "Circe",
        description:
            "A single page application that allows users to trade plants with each other.",
        repositoryUrl: "https://github.com/JoaoTeixeira13/circe",
        imageUrl: "/works/circe.gif",
        techStack: [JavaScript, AWS, Node, Express, PostgreSQL, React, Redux],
        tags: [FullStackApplication, React, All],
    },
    {
        title: "Social Network",
        description:
            "A single page application that allows users to join a social network.",
        repositoryUrl: "https://github.com/JoaoTeixeira13/social-network",
        imageUrl: "/works/socialNetwork.gif",
        techStack: [
            JavaScript,
            AWS,
            Node,
            Express,
            PostgreSQL,
            SocketIO,
            React,
            Redux,
        ],
        tags: [FullStackApplication, React, All],
    },
    {
        title: "Image Board",
        description: "An Instagram-like, single page image board application.",
        repositoryUrl: "https://github.com/JoaoTeixeira13/image-board",
        imageUrl: "/works/imageBoard.gif",
        techStack: [JavaScript, Vue, Node, Express, PostgreSQL, AWS],
        tags: [FullStackApplication, Vue, All],
    },
    {
        title: "Petition",
        description:
            "A multi page application that allows users to register and sign a petition.",
        repositoryUrl: "https://github.com/JoaoTeixeira13/petition",
        imageUrl: "/works/petition.gif",
        techStack: [JavaScript, Handlebars, Node, Express, PostgreSQL, Heroku],
        tags: [FullStackApplication, All],
    },
    {
        title: "Connect Four",
        description:
            "A two-player game where the first user to connect four pieces wins.",
        repositoryUrl: "https://github.com/JoaoTeixeira13/connect-four",
        imageUrl: "/works/connectFour.gif",
        techStack: [JavaScript],
        tags: [Game, All],
    },
    {
        title: "Portfolio",
        description: "The page you are currently viewing.",
        repositoryUrl: "https://github.com/JoaoTeixeira13/portfolio",
        imageUrl: "/works/portfolio.gif",
        techStack: [TypeScript, React],
        tags: [React, All],
    },
];
